import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const BlockHeading = styled.h2`
	${tw`
    text-tbrteal
 		font-serif text-3xl md:text-4xl
		w-full md:w-5/6
		pb-2 md:pb-4
		relative z-30
`}
	${props =>
		props.position !== 'top' &&
		tw`
			mt-0
			md:pt-4
	`}

	${props =>
		props.position === 'top' &&
		tw`
			mt-0
			pt-2
	`}
`;

export const SecondaryContentBlockWrapper = styled.section`
	${tw`
		m-8
		relative
		block
		w-5/6 md:w-3/4
		mx-auto z-30
		mt-0
	`}

	${props =>
		props.position === 'top' &&
		tw`
			mt-0
	`}

	${props =>
		props.frame === true &&
		tw`
			pb-16 md:pb-24
			mb-16 md:mb-24
	`}
`;

export const BlockContent = styled.div`
	${tw`
	leading-8
relative z-30

  `}
	a {
		${tw`
			text-tbrteal underline
		`}
	}
	${props =>
		props.columns === 2 &&
		tw`
	
	`}

	> ul {
		${tw`
			list-outside
			grid md:grid-cols-2 gap-4

  `}
	}
`;

export const Stylediframe = styled.iframe`
	${tw`
	bg-palegreen
	mt-8
	p-2 md:p-4
	h-72
	`}
	width: 100%;
	border: 0;
	style="border:0;"
`;
